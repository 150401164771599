<template>
  <div class="my-btns">
    <el-button
            style="width: 80px;"
            type="primary"
            size="small"
            @click="$parent.$parent.add()"
            :loading="posting"
            v-if="tool.checkButton('emission-add')"
    >
      {{ $t("global.add") }}
    </el-button>
    <el-button
            style="width: 80px;"
            size="small"
            @click="$parent.$parent.removeBatch()"
            :loading="posting"
            v-if="tool.checkButton('emission-list')"
    >
      {{ $t("global.export") }}
    </el-button>
  </div>
</template>

<script>
export default {
  directives: {},
  filters: {},
  loading: false,
  props: {},
  data () {
    return {
      posting: false,
      isModeInfo: false
    }
  },
  computed: {},
  watch: {},
  beforeCreate () { },
  created () {

  },
  beforeMount () { },
  mounted () { },
  activated () { },
  deactivated () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  methods: {

  }
}
</script>
