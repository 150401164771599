<script>
import base from '@/templates/list'
import api from '@/api'
import tool from '@/tool'
import Btns from './btns'
export default {
  directives: {},
  filters: {},
  components: {
    Btns
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      tableAction: {
        add: { show: tool.checkButton('system-msg-template'), title: '新增' },
        view: { show: true, title: '减排方案推荐' },
        edit: { show: true, title: '编辑' },
        remove: { show: true, title: '删除' },
        valid: { show: true, title: '停用' }
      },
      showList: [
        {
          copName: 'el-cascader',
          placeholder: 'list.部门',
          key: 'orgId',
          productCategoryId: [],
          attrs: ['placeholder', 'cascader'],
          name: '',
          cascaderOptions: []
        },
        {
          copName: 'el-input',
          placeholder: 'list.排放源名称',
          key: 'name',
          name: '',
          attrs: ['placeholder']
        },
        {
          copName: 'el-date-picker',
          placeholder: 'list.创建时间',
          key: 'createTime',
          dates: [],
          attrs: ['copType', 'valueFormat', 'placeholder'],
          copType: 'date',
          valueFormat: 'yyyy-MM-dd'
        }
      ],
      json_fileName: '',
      tableTile: [
        {
          key: '0',
          columnLabel: 'list.序号',
          width: '80',
          type: 'index'
        },
        {
          key: 'name',
          columnLabel: 'list.排放源名称',
          prop: 'name',
          width: ''
        },
        {
          key: 'typeName',
          columnLabel: 'list.排放源类别',
          prop: 'typeName',
          width: ''
        },
        {
          key: 'status',
          columnLabel: 'list.状态',
          prop: 'status',
          width: '',
          formatter: (row, column, ddd) => {
            var map = {
              0: '启用',
              1: '停用'
            }
            return map[row.status]
          }
        },
        {
          key: 'orgName',
          columnLabel: 'list.部门',
          prop: 'orgName',
          width: ''
        },
        {
          key: 'principalName',
          columnLabel: 'list.负责人',
          prop: 'principalName',
          width: ''
        },
        {
          key: 'remark',
          columnLabel: 'list.备注',
          prop: 'remark',
          width: ''
        }
      ]
    }
  },
  computed: {},
  watch: {},
  beforeCreate () {
  },
  created () {
    api.organization.queryOrgTree({}).then((res) => {
      var aa = tool.treearr(res.data, true)
      this.showList[0].cascaderOptions = aa
    })
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    init () {
      this.toPage(1)
    },
    add () {
      this.$router.push('/emission/emission-add')
    },
    onSubmit () {
      this.loading = true

      var query = {
        query: this.formInline.query,
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          orgId: this.formInline.orgId,
          name: this.formInline.name,
          createTime: this.formInline.createTime,
          emissionsFactorList: []
        }
      }
      api.emission.queryPage(query)
        .then((res) => {
          if (res.data && res.data.records) {
            this.tableData = res.data.records.map((item) => {
              return {
                ...item,
                id: item.id || ''
                // createTime: this.$moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') || ''
              }
            })
            this.formInline.page.total = res.data.total
          }
          this.loading = false
        })
    },
    query (params) {
      return api.detectionInstitution.query(params)
    },
    edit (id) {
      this.$router.push('/emission/emission-add/' + id)
    },
    view (id) {
      this.$router.push('/emission/emission-info/' + id)
    },
    enable (id) {
      this.$confirm('请确认是否停用此数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.emission
          .updateEmissionStatusById({ id: id, status: 1 })
          .then(() => {

            this.toPage(1)
          })
      })
    },
    disable (id) {
      this.$confirm('请确认是否启用此数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.emission
          .updateEmissionStatusById({ id: id, status: 0 })
          .then(() => {

            this.toPage(1)
          })
      })
    },
    handleWarnDialog () {
      // alert(222)
      const currentSelect = this.$refs.multipleTable.selection

      if (currentSelect.length === 0) {
        this.$message.error('请先勾选记录')
        return
      }
      if (currentSelect.length > 1) {
        this.$message.error('该操作只能单选一条记录')
        return
      }
      this.$refs.myDialog.show(currentSelect)
    },
    removeBatch () {
      const currentSelect = this.$refs.multipleTable.selection

      if (currentSelect.length === 0) {
        this.$message.error('请先勾选记录')
        return
      }

      var ids = currentSelect.map(ele => ele.id)

      this.$confirm('删除后会导致相关数据显示异常，是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.emission.remove({ ids })
          .then(() => {

            this.toPage(1)
          })
      })
    },
    remove (id) {
      this.$confirm('删除后会导致相关数据显示异常，是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.emission.remove({ id: id })
          .then(() => {

            this.toPage(1)
          })
      })
    }
  }
}
</script>
